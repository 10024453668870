import { Button } from '@/components/Button/Button';
import { Image } from '@/components/Image/Image';

import { useWithGCBValidator } from '../../Footer.hooks';

import styles from './GCBValidator.module.scss';

const GCBValidator = () => {
  const withGCBValidator = useWithGCBValidator();

  if (!withGCBValidator) {
    return undefined;
  }

  return (
    <Button as="link" href={withGCBValidator.href} variant="text" target="_blank" className={styles.root}>
      <Image
        src={withGCBValidator.imageSrc}
        alt={withGCBValidator.imageAlt}
        width={42}
        height={24}
        className={styles.icon}
      />
    </Button>
  );
};

export { GCBValidator };
