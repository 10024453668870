import type { NavigationAnalytics } from './Navigation.types';

export const analytics: NavigationAnalytics = {
  download: {
    android: {
      click: {
        eventName: 'header_downloadAppLanding_android_icon_click',
      },
    },
  },
};
