import type { UrlObject } from 'url';
import type { LinkUrl } from '@mwl/core-lib';
import { selectUserIsAuthenticated } from '@mwl/core-lib';

import { routes } from '@/constants';
import { useTypedSelector } from '@/hooks';

export function useIsGameRoute(href: LinkUrl): href is UrlObject {
  return typeof href === 'object' && href.pathname === routes.casino.game;
}

export function useGameHref(href: LinkUrl) {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);

  const isGameRoute = useIsGameRoute(href);

  return isGameRoute
    ? {
        ...href,
        query: {
          ...(typeof href.query === 'object' && href.query),
          mode: isAuth ? 'real' : 'demo',
        },
      }
    : href;
}
