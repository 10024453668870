import type { UrlObject } from 'url';
import { getTestProps } from '@mwl/core-lib';

import { routes } from '@/constants';
import { userMenuObject } from '@/utils';

import type { SidebarMenuElements } from './Sidebar.types';

export const getSidebarMenuElements = (aviatorHref: UrlObject): SidebarMenuElements => {
  return [
    {
      ...getTestProps(userMenuObject.promoSection.nodeProps),
      label: ['sidebar.menu.promotions', 'promotions'],
      icon: '/assets/sidebar/icons/promotions.svg',
      children: [
        {
          ...getTestProps(userMenuObject.promoSection.promoItem('bonus').nodeProps),
          label: ['sidebar.menu.bonus', 'bonus'],
          link: routes.bonuses,
        },
        {
          ...getTestProps(userMenuObject.promoSection.promoItem('promo').nodeProps),
          label: ['sidebar.menu.promo', 'promo'],
          link: routes.promo.home,
        },
        {
          ...getTestProps(userMenuObject.promoSection.promoItem('tournaments').nodeProps),
          label: ['sidebar.menu.tournaments', 'tournaments'],
          link: routes.tournaments.home,
        },
        {
          ...getTestProps(userMenuObject.promoSection.promoItem('loyalty').nodeProps),
          label: ['sidebar.menu.loyalty', 'loyalty program'],
          link: routes.loyalty,
          onlyAuth: true,
        },
      ],
    },
    {
      label: ['sidebar.menu.lucky_wheel', 'lucky wheel'],
      icon: '/assets/sidebar/images/luckyWheel.png',
      isLuckyWheelBlock: true,
    },
    // TODO Временно скрываем
    // {
    //   label: ['sidebar.menu.sportsBet', 'sports bet'],
    //   onlyMobile: true,
    //   icon: '/assets/sidebar/icons/sportsBet.svg',
    //   children: [
    //     { label: ['sidebar.menu.live', 'live'], link: '' },
    //     { label: ['sidebar.menu.favorites', 'favorites'], link: '', onlyAuth: true },
    //     { label: ['sidebar.menu.line', 'line'], link: '' },
    //   ],
    // },
    {
      ...getTestProps(userMenuObject.casinoSection.nodeProps),
      label: ['sidebar.menu.casino', 'casino'],
      link: routes.casino.home,
      icon: '/assets/sidebar/icons/casino.svg',
      onlyMobile: true,
    },
    {
      ...getTestProps(userMenuObject.liveCasinoSection.nodeProps),
      label: ['sidebar.menu.liveCasino', 'live casino'],
      link: routes.liveCasino.home,
      icon: '/assets/sidebar/icons/liveCasino.svg',
      onlyMobile: true,
    },
    {
      ...getTestProps(userMenuObject.aviatorSection.nodeProps),
      label: ['sidebar.menu.aviator', 'aviator'],
      link: aviatorHref,
      icon: '/assets/sidebar/icons/aviator.svg',
      onlyMobile: true,
    },
    {
      ...getTestProps(userMenuObject.bonusBuySection.nodeProps),
      label: ['sidebar.menu.buyBonus', 'buy bonus'],
      link: routes.casino.buyBonus,
      icon: '/assets/sidebar/icons/buyBonus.svg',
      onlyMobile: true,
    },
    { isDivider: true },
    {
      ...getTestProps(userMenuObject.faqSection.nodeProps),
      label: ['sidebar.menu.faq', 'faq'],
      link: routes.faq,
      icon: '/assets/sidebar/icons/faq.svg',
    },
    {
      ...getTestProps(userMenuObject.historySection.nodeProps),
      label: ['sidebar.menu.request_history', 'request history'],
      link: routes.profile.requestHistory,
      icon: '/assets/sidebar/icons/requestHistory.svg',
      onlyAuth: true,
    },
    {
      ...getTestProps(userMenuObject.supportSection.nodeProps),
      label: ['sidebar.menu.support', 'support'],
      link: '',
      icon: '/assets/sidebar/icons/support.svg',
      clickHandlerType: 'support',
    },
    { isDivider: true },
    {
      isDownloadBlock: true,
      analytics: {
        android: {
          click: {
            eventName: 'sidebar_downloadApp_android_icon_click',
          },
        },
      },
    },
    // TODO Временно скрываем в рамках MWL-6896
    // {
    //   header: ['sidebar.menu.timeZone', 'time zone'],
    //   label: ['sidebar.menu.timeZone', 'time zone'],
    //   children: [],
    // },
    // {
    //   header: ['sidebar.menu.oddsFormat', 'odds format'],
    //   label: ['sidebar.menu.decimal', 'decimal'],
    //   children: [],
    // },
  ];
};
