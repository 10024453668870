import { type FC, useCallback, useEffect, useRef, useState } from 'react';
import { checkIsServer, useHeaderDimensions } from '@mwl/core-lib';

import { useMediaWidthDown } from '@/hooks';
import { headerObject } from '@/utils/markerTree';

import { Navigation } from './components/Navigation/Navigation';
import type { HeaderProps } from './Header.types';

import styles from './Header.module.scss';

const Header: FC<HeaderProps> = ({ className }) => {
  const { ref } = useHeaderDimensions();
  const prevScrollTop = useRef(0);
  const headerHeight = ref.current?.clientHeight ?? 100;

  const is850Screen = useMediaWidthDown('screen850');

  const [isHidden, setIsHidden] = useState(false);

  const handleScroll = useCallback(() => {
    if (checkIsServer()) {
      return;
    }

    const { scrollTop } = document.documentElement;
    const isScrollingUp = scrollTop <= prevScrollTop.current;

    prevScrollTop.current = scrollTop;

    if (isScrollingUp) {
      setIsHidden(false);
    } else {
      setIsHidden(scrollTop >= headerHeight);
    }
  }, [headerHeight, prevScrollTop]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <header
      {...headerObject.nodeProps}
      ref={ref}
      className={cn(styles.root, className, { [styles.hidden]: is850Screen && isHidden })}
    >
      <Navigation />
    </header>
  );
};

export { Header };
