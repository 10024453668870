import type { FC } from 'react';

import styles from './ButtonLuckyWheelLogo.module.scss';

const ButtonLuckyWheelLogo: FC = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.root}
    >
      <path
        d="M26.831 7.472a20.759 20.759 0 0 0-13.045 0c-1.608.553-2.562-.447-3.267-1.976-.695-1.493-.757-2.98 1.361-3.781 5.937-2.247 10.723-2.326 16.857 0 2.118.802 2.056 2.287 1.361 3.781-.704 1.529-1.659 2.53-3.267 1.976Z"
        fill="#FFBE45"
      />
      <g className={styles.wheelBody}>
        <path
          d="M20.309 39.794c10.664 0 19.308-8.645 19.308-19.308 0-10.664-8.644-19.31-19.308-19.31S1 9.823 1 20.487c0 10.663 8.645 19.308 19.309 19.308Z"
          fill="#FFBE45"
        />
        <path
          d="M38.18 20.928c.246-9.875-7.56-18.08-17.433-18.325-9.875-.246-18.08 7.56-18.325 17.434-.246 9.875 7.56 18.08 17.434 18.325 9.874.246 18.078-7.56 18.324-17.434Z"
          fill="#FF9100"
        />
        <path
          d="M20.308 4.593c-8.762 0-15.892 7.13-15.892 15.892 0 8.764 7.13 15.893 15.892 15.893 8.763 0 15.893-7.13 15.893-15.893 0-8.763-7.13-15.892-15.893-15.892Zm0 29.971c-7.762 0-14.078-6.316-14.078-14.078 0-7.763 6.316-14.079 14.078-14.079 7.763 0 14.079 6.316 14.079 14.079 0 7.762-6.316 14.078-14.079 14.078Z"
          fill="#F5E8C1"
        />
        <path
          d="M20.308 4.593c-8.762 0-15.892 7.13-15.892 15.892 0 8.764 7.13 15.893 15.892 15.893 8.763 0 15.893-7.13 15.893-15.893 0-8.763-7.13-15.892-15.893-15.892Zm0 31.075c-8.37 0-15.182-6.81-15.182-15.183 0-8.371 6.811-15.182 15.182-15.182 8.372 0 15.183 6.81 15.183 15.182S28.68 35.668 20.308 35.668Z"
          fill="#F0EBDB"
        />
        <path
          d="m28.242 6.585-8.02 13.874-7.949-13.761c2.34-1.358 5.121-2.177 8.016-2.177 2.895 0 5.614.707 7.953 2.064Z"
          fill="#F71E81"
        />
        <path
          d="m12.28 6.695 7.944 13.765-15.892.003a15.79 15.79 0 0 1 2.125-7.952 15.783 15.783 0 0 1 5.824-5.816Z"
          fill="#FF4545"
        />
        <path
          d="m4.273 20.457 15.95.003-7.972 13.832a15.858 15.858 0 0 1-5.845-5.844 15.88 15.88 0 0 1-2.133-7.991Z"
          fill="#FBED46"
        />
        <path
          d="m12.273 34.334 7.949-13.874 8.038 13.909c-2.34 1.368-5.228 2.221-8.038 2.118-2.895 0-5.61-.784-7.949-2.153Z"
          fill="#42EA6A"
        />
        <path
          d="M28.318 34.414 20.223 20.46l16.195-.003c.006 2.742-.69 5.52-2.166 8.061a16.049 16.049 0 0 1-5.934 5.896Z"
          fill="#24BDFF"
        />
        <path
          d="m36.434 20.462-16.21-.003L28.36 6.626c2.392 1.348 4.431 3.377 5.907 5.884a15.552 15.552 0 0 1 2.168 7.952Z"
          fill="#934896"
        />
        <path d="m12.681 6.464-.822.474 7.947 13.765.822-.475-7.947-13.764Z" fill="#FF9100" />
        <path
          d="M19.927 19.988H3.852v.95h16.075v-.95ZM18.977 21.62l-7.184 12.496.823.474L19.8 22.093l-.823-.473ZM21.633 22.252l-.817.483 7.174 12.113.817-.484-7.174-12.112ZM36.95 19.986H23.388v.95H36.95v-.95ZM27.866 6.257 21.39 17.481l.822.474 6.478-11.223-.823-.475Z"
          fill="#FF9100"
        />
      </g>

      <path
        d="M23.474 3.483c0 2.324-3.164 5.695-3.164 5.695s-3.163-3.371-3.163-5.695c0-2.323 1.416-2.718 3.163-2.718 1.748 0 3.164.395 3.164 2.718Z"
        fill="#FFBE45"
      />
      <path
        d="M20.309 8.264c-1.043-1.285-2.215-3.1-2.215-4.247 0-1.39.476-1.77 2.214-1.77 1.74 0 2.215.38 2.215 1.77 0 1.15-1.171 2.964-2.214 4.247ZM21.845 24.22a4.035 4.035 0 1 0-3.073-7.462 4.035 4.035 0 0 0 3.073 7.462Z"
        fill="#FEF697"
      />
      <path d="M23.473 20.486a3.165 3.165 0 1 1-6.33 0 3.165 3.165 0 0 1 6.33 0Z" fill="#FFBE45" />
      <path
        d="M18.38 23.853a.708.708 0 0 1-.26-.973.703.703 0 0 1 .967-.26c.342.194.46.63.265.967a.713.713 0 0 1-.972.266Zm2.9-.27a.712.712 0 0 1 .256-.973.712.712 0 0 1 .973.26c.199.338.085.774-.257.969a.709.709 0 0 1-.355.1.713.713 0 0 1-.617-.356Zm-4.85-3.075a.706.706 0 0 1 .706-.712.708.708 0 0 1 .717.707.712.712 0 1 1-1.424.005Zm6.33-.02v-.028a.71.71 0 0 1 .702-.721.714.714 0 0 1 .722.702v.029a.71.71 0 1 1-1.424.019Zm-4.67-2.367a.71.71 0 1 1 1.224-.721.712.712 0 0 1-1.223.72Zm3.422.227a.712.712 0 0 1-.27-.968.72.72 0 0 1 .972-.27.714.714 0 0 1-.35 1.333.674.674 0 0 1-.352-.095Z"
        fill="#FEF697"
      />
    </svg>
  );
};

export { ButtonLuckyWheelLogo };
