import type { FC } from 'react';

import PoweredByIcon from '@public/assets/common/icons/poweredBy.svg';

import type { PoweredByProps } from './PoweredBy.types';

import styles from './PoweredBy.module.scss';

const PoweredBy: FC<PoweredByProps> = ({ className }) => {
  return (
    <div className={cn(styles.root, className)}>
      <PoweredByIcon className={styles.icon} />
    </div>
  );
};

export { PoweredBy };
