import type { FC } from 'react';
import { useTranslation } from 'next-i18next';

import LuckyWheelBg from '@public/assets/common/icons/wheelBg.svg';

import { Button } from '../Button/Button';

import { ButtonLuckyWheelLogo } from './components/ButtonLuckyWheelLogo/ButtonLuckyWheelLogo';
import type { ButtonLuckyWheelProps } from './ButtonLuckyWheel.types';

import styles from './ButtonLuckyWheel.module.scss';

const ButtonLuckyWheel: FC<ButtonLuckyWheelProps> = ({ className, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <Button
      className={cn(styles.root, className)}
      color="white"
      variant="text"
      fontWeight="bold"
      cut={false}
      size="none"
      {...props}
    >
      <LuckyWheelBg className={styles.bg} />
      <ButtonLuckyWheelLogo />
      <span className={styles.text}>{t('buttons.lucky_wheel', 'Lucky Wheel')}</span>
    </Button>
  );
};

export * from './ButtonLuckyWheel.types';
export { ButtonLuckyWheel };
