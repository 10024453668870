import type { FC } from 'react';
import { selectUserIsAuthenticated } from '@mwl/core-lib';

import { mobileNavMenuAuthorizedItems, mobileNavMenuUnauthorizedItems } from '@/constants';
import { useTypedSelector } from '@/hooks';

import { MenuItem } from './components/MenuItem/MenuItem';
import type { MobileMenuProps } from './MobileMenu.types';

import styles from './MobileMenu.module.scss';

const MobileMenu: FC<MobileMenuProps> = ({ className }) => {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const menuItems = isAuth ? mobileNavMenuAuthorizedItems : mobileNavMenuUnauthorizedItems;

  return (
    <div className={cn(styles.root, className)}>
      {menuItems.map((menuItem) => (
        <MenuItem
          {...menuItem}
          id={menuItem.key}
          key={menuItem.key}
          className={{ item: styles.item, activeItem: styles.activeItem }}
        />
      ))}
    </div>
  );
};

export { MobileMenu };
