import type { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData } from '@mwl/core-lib';

import { Button } from '@/components/Button/Button';
import { appPageObject } from '@/utils';

import { links } from './Navigation.data';
import type { NavigationProps } from './Navigation.types';

import styles from './Navigation.module.scss';

const Navigation: FC<NavigationProps> = ({ className }) => {
  const { t } = useTranslation();

  const router = useRouter();

  return (
    <div className={cn(styles.root, className)}>
      {links.map(({ id, href, key, text, analytics }) => (
        <Button
          {...appPageObject.footer.links(key).nodeProps}
          as="link"
          href={href}
          key={id}
          fontSize="sm"
          className={styles.link}
          variant="text"
          color="grey"
          fontWeight="bold"
          active={href.startsWith(router.pathname)}
          analytics={extendAnalyticsData(analytics, { href, text: t(key, text) })}
        >
          {t(key, text)}
        </Button>
      ))}
    </div>
  );
};

export { Navigation };
