import type { FC } from 'react';
import { memo } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/Button/Button';
import { routes } from '@/constants';
import { useIsMenuItemActive } from '@/hooks';

import { useGameHref } from './MenuItem.hooks';
import type { MenuItemProps } from './MenuItem.types';

import styles from './MenuItem.module.scss';

const BaseMenuItem: FC<MenuItemProps> = ({ href, text, id, Icon, className }) => {
  const { t } = useTranslation();
  const { pathname, asPath } = useRouter();
  let isActive = useIsMenuItemActive(href);

  if (isActive && href === routes.home && pathname !== routes.home) {
    isActive = false;
  }

  const gameHref = useGameHref(typeof href === 'object' ? { pathname: asPath, ...href } : href);

  return (
    <Button
      as="link"
      variant="text"
      skew="right"
      fontSize="sm"
      fontWeight="bold"
      color="red"
      href={gameHref}
      className={cn(styles.root, className.item, { [cn(styles.active, className.activeItem)]: isActive })}
    >
      {Icon && <Icon className={styles.icon} />}
      <span className={cn(styles.itemText)}>{t(id, text).toUpperCase()}</span>
    </Button>
  );
};

export const MenuItem = memo(BaseMenuItem);
