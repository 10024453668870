import type { FC } from 'react';

import BonusIcon from '@public/assets/common/icons/bonus.svg';
import BonusOpenIcon from '@public/assets/common/icons/bonusOpen.svg';

import { ButtonIcon } from '../ButtonIcon/ButtonIcon';

import type { BonusButtonProps } from './BonusButton.types';

import styles from './BonusButton.module.scss';

const BonusButton: FC<BonusButtonProps> = ({ className, Icon = BonusIcon, IconHovered = BonusOpenIcon, ...props }) => {
  return (
    <ButtonIcon color="white" variant="fulfilled" className={cn(styles.root, className)} {...props} aria-label="bonus">
      <Icon className={styles.icon} />
      <IconHovered className={styles.iconHover} />
    </ButtonIcon>
  );
};

export { BonusButton };
