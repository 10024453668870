import { useEffect, useMemo, useState } from 'react';
import { loadBonusListRequest, selectBonusList, selectFreespinList, selectUserCurrency } from '@mwl/core-lib';

import { useTypedDispatch, useTypedSelector } from '@/hooks';

import type { SidebarBonusItemProps } from '../components/SidebarBonusItem/SidebarBonusItem.types';
import type { SidebarFreespinItemProps } from '../components/SidebarFreespinItem/SidebarFreespinItem.types';

type SidebarBonusType = 'bonuses' | 'freespins';
interface BaseItem extends Omit<SidebarBonusItemProps, 'onComplete' | 'className'> {}

interface BonusItem extends BaseItem {
  id: string;
}

type FreespinItem = Omit<SidebarFreespinItemProps, 'onComplete' | 'className'>;

export function useSidebarBonus() {
  const dispatch = useTypedDispatch();

  const freespinsList = useTypedSelector(selectFreespinList);
  const bonusList = useTypedSelector(selectBonusList);
  const currency = useTypedSelector(selectUserCurrency);

  const [state, setState] = useState<Record<SidebarBonusType, boolean>>({ bonuses: true, freespins: true });

  const bonusListCasino = useMemo(
    () => bonusList.filter((item) => item.applicationType.toUpperCase() !== 'SPORT'),
    [bonusList],
  );

  const bonusItem = useMemo<BonusItem | null>(() => {
    if (!bonusListCasino.length) {
      return null;
    }

    const { id, balance, rollingBalance, requiredRollingBalance, expirationDate, applicationType } = bonusListCasino[0];

    return {
      id,
      balance,
      currency,
      applicationType,
      rollingBalance,
      requiredRollingBalance,
      expirationDate: expirationDate ? new Date(expirationDate).getTime() : undefined,
    };
  }, [bonusListCasino, currency]);

  const freespinItem = useMemo<FreespinItem | null>(() => {
    if (!freespinsList.length) {
      return null;
    }

    const { availableGames, count, expiredAt, playedCount, id: freespinsId } = freespinsList[0];

    const game = availableGames?.length ? availableGames[0] : undefined;

    if (!game) {
      return null;
    }

    return {
      id: game.id,
      freespinsId,
      title: game.title,
      image: game.image,
      expirationDate: expiredAt ? new Date(expiredAt).getTime() : undefined,
      count,
      playedCount,
    };
  }, [freespinsList]);

  const handleComplete = (type: SidebarBonusType) => {
    setState((prev) => ({ ...prev, [type]: false }));
  };

  useEffect(() => {
    if (bonusItem?.expirationDate && bonusItem.expirationDate <= Date.now()) {
      setState((prev) => ({ ...prev, bonuses: false }));
    }

    if (freespinItem?.expirationDate && freespinItem.expirationDate <= Date.now()) {
      setState((prev) => ({ ...prev, freespins: false }));
    }
  }, [bonusItem, freespinItem]);

  useEffect(() => {
    if (currency) {
      dispatch(loadBonusListRequest());
    }
  }, [currency, dispatch]);

  return {
    handleComplete,
    bonusItem,
    bonusList,
    freespinItem,
    freespinsList,
    ...state,
  };
}
