import { useEffect, useState } from 'react';
import { checkIsServer } from '@mwl/core-lib';

import { ALLOWED_VALIDATOR_HOSTS, VALIDATOR_GCB_PARAMS_BY_HOST } from '@/constants';

export const useWithValidator = () => {
  const [withValidator, setWithValidator] = useState(false);

  useEffect(() => {
    const isValidatorAllowed = ALLOWED_VALIDATOR_HOSTS.includes(window.location.host);
    setWithValidator(isValidatorAllowed);
  }, []);

  return withValidator;
};

export const useWithGCBValidator = () => {
  const isServer = checkIsServer();
  const withValidator = useWithValidator();

  if (withValidator && !isServer) {
    return VALIDATOR_GCB_PARAMS_BY_HOST?.[window.location.host];
  }

  return undefined;
};
