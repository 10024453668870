import type { FC } from 'react';
import type { BaseQueryPopupParams } from '@mwl/core-lib';
import {
  getTestProps,
  numberFormatter,
  selectUserBalanceAmount,
  selectUserCurrencySymbol,
  useQueryPopupOpen,
} from '@mwl/core-lib';

import AddRoundIcon from '@public/assets/common/icons/addRound.svg';

import { Button } from '@/components/Button/Button';
import { useTypedSelector } from '@/hooks';

import { ButtonIcon } from '../ButtonIcon/ButtonIcon';

import type { BalanceProps } from './Balance.types';

import styles from './Balance.module.scss';

const Balance: FC<BalanceProps> = ({ className, withAmount = true, ...props }) => {
  const balance = useTypedSelector(selectUserBalanceAmount);
  const currencySymbol = useTypedSelector(selectUserCurrencySymbol);

  const { createLinkProps } = useQueryPopupOpen();

  const linkProps = createLinkProps({ queryName: 'deposit' as BaseQueryPopupParams });

  return (
    <Button {...getTestProps(props)} as="link" {...linkProps} variant="text" className={cn(styles.root, className)}>
      {withAmount && (
        <>
          <div className={styles.wrapper} />
          <span className={styles.balance}>{numberFormatter(balance, { prefix: `${currencySymbol} ` })}</span>
        </>
      )}
      <ButtonIcon
        as="button"
        variant="fulfilled"
        color="red"
        className={styles.add}
        cut={false}
        aria-label="balance add"
      >
        <AddRoundIcon />
      </ButtonIcon>
    </Button>
  );
};

export { Balance };
