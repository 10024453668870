import type { FC } from 'react';
import { useTranslation } from 'next-i18next';

import ShieldIcon from '@public/assets/common/icons/shield.svg';

import { ProgressBar } from '@/components/ProgressBar/ProgressBar';

import { Rank } from '../Rank/Rank';

import type { UserRankProps } from './UserRank.types';

import styles from './UserRank.module.scss';

const maxLevel = 20;

const UserRank: FC<UserRankProps> = ({ level, progress, rank }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.shield}>
        <div className={styles.shieldIcon}>
          <ShieldIcon className={styles.icon} />
        </div>
        <span className={styles.value}>
          {level}/{maxLevel}
        </span>
      </div>
      <div className={styles.progressRank}>
        <div className={styles.progressWrapper}>
          <p className={styles.levelTitle}>{t('sidebar.level', 'Level')}</p>
          <ProgressBar
            percent={progress}
            showValue={false}
            className={styles.progressBar}
            showShadow
            classes={{ container: styles.progressContainer, bar: styles.progress }}
          />
        </div>
        <div className={styles.rankExperience}>
          <Rank rank={rank} />
          {/* {typeof maxExperience !== 'undefined' && (
            <div className={styles.experience}>
              <span className={styles.current}>{experience}</span>/{maxExperience}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export { UserRank };
