import { type ProductType, getProviderUrl } from '@mwl/core-lib';

import type { ButtonIconAsButton, ButtonIconAsLink } from '@/components/ButtonIcon/ButtonIcon.types';
import { routes } from '@/constants';

export const getButtonProps = (providerName: string, productTypeList?: Array<ProductType>) => {
  if (!productTypeList?.length) {
    return { as: 'button' } as Omit<ButtonIconAsButton, 'children'>;
  }

  const providerHref = getProviderUrl({ providerTitle: providerName, providerPathname: routes.providers.provider });

  if (!providerHref) {
    return { as: 'button' } as Omit<ButtonIconAsButton, 'children'>;
  }

  return { as: 'link', href: providerHref } as Omit<ButtonIconAsLink, 'children'>;
};
