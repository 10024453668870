import type { FC } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'next-i18next';
import {
  selectLocalesMap,
  selectUserIsAuthenticated,
  selectUserLocale,
  updateSettingsRequest,
  useAvailableLocales,
} from '@mwl/core-lib';

import ArrowIcon from '@public/assets/common/icons/arrow.svg';

import type { DropdownOnToggle } from '@/components/Dropdown/Dropdown';
import { Dropdown } from '@/components/Dropdown/Dropdown';
import { Image } from '@/components/Image/Image';
import { useMediaWidthDown, useTypedSelector } from '@/hooks';
import { toggleSidebar } from '@/store/features/ui/actions';

import type { LocaleButtonProps } from './LocaleButton.types';
import { groupBySize } from './LocaleButton.utils';

import styles from './LocaleButton.module.scss';

const BaseLocaleButton: FC<LocaleButtonProps> = ({
  displayMode = 'list',
  className,
  testMarker,
  idleTimeout = 10_000,
}) => {
  const { t } = useTranslation();

  const [active, setActive] = useState(false);

  const dispatch = useDispatch();

  const is850Screen = useMediaWidthDown('screen850');

  const isAuth = useTypedSelector(selectUserIsAuthenticated);

  const localesMap = useTypedSelector(selectLocalesMap);
  const locale = useTypedSelector(selectUserLocale);

  const locales = useAvailableLocales();

  const language = localesMap[locale];

  const handleToggleDropdown = useCallback<DropdownOnToggle>((isOpen) => {
    if (typeof isOpen === 'boolean') {
      setActive(isOpen);
    } else {
      setActive((prevActive) => !prevActive);
    }
  }, []);

  const handleChangeLocale = useCallback(
    (nextLocale: string) => {
      dispatch(toggleSidebar(false));

      const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';

      if (isAuth) {
        dispatch(updateSettingsRequest({ locale: nextLocale, timezone }));
      } else {
        dispatch(updateSettingsRequest({ locale: nextLocale }));
      }
    },
    [isAuth, dispatch],
  );

  const renderHeader = useCallback(
    (context: 'root' | 'dropdown') => {
      return (
        <div
          {...testMarker?.trigger.nodeProps}
          className={cn(styles.root, context === 'root' && className, { [styles.active]: active })}
        >
          {language?.image && (
            <span className={styles.flag}>
              <Image src={language.image} width={20} height={20} alt={`language ${language.code}`} />
            </span>
          )}
          <span className={styles.text}>{t(language?.translationKey)}</span>
          <span
            role="presentation"
            className={styles.arrow}
            onClick={context === 'dropdown' ? () => handleToggleDropdown() : undefined}
          >
            <ArrowIcon />
          </span>
        </div>
      );
    },
    [className, handleToggleDropdown, language, t, testMarker, active],
  );

  const rootHeader = useMemo(() => renderHeader('root'), [renderHeader]);
  const dropdownHeader = useMemo(() => renderHeader('dropdown'), [renderHeader]);

  if (!language) {
    return null;
  }

  if (isMobile) {
    return (
      <div className={cn(styles.mobileSelectWrapper, className)}>
        {rootHeader}
        <select
          className={styles.mobileSelect}
          value={locale}
          onChange={(event) => handleChangeLocale(event.target.value)}
        >
          {locales.map(({ code, translationKey }) => {
            return (
              <option key={code} value={code}>
                {t(translationKey)}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  const localeRows = groupBySize(locales, 5);

  const content =
    displayMode === 'grid'
      ? localeRows.map((row, index) => {
          return (
            <div key={index} className={styles.listRow}>
              {row.map(({ code, image, translationKey }) => {
                return (
                  <div
                    {...testMarker?.item.nodeProps}
                    className={cn(styles.locale, { [styles.selected]: code === locale })}
                    key={code}
                    onClick={() => handleChangeLocale(code)}
                    tabIndex={-1}
                    role="presentation"
                  >
                    <span className={styles.flag}>
                      {image && <Image src={image} width={20} height={20} alt={`flag ${code}`} />}
                    </span>
                    <span className={styles.text}>{t(translationKey)}</span>
                  </div>
                );
              })}
            </div>
          );
        })
      : locales.map(({ code, image, translationKey }) => {
          return (
            <div
              {...testMarker?.item.nodeProps}
              className={cn(styles.locale, { [styles.selected]: code === locale })}
              key={code}
              onClick={() => handleChangeLocale(code)}
              tabIndex={-1}
              role="presentation"
            >
              <span className={styles.flag}>
                {image && <Image src={image} width={20} height={20} alt={`flag ${code}`} />}
              </span>
              <span className={styles.text}>{t(translationKey)}</span>
            </div>
          );
        });

  return (
    <Dropdown
      idleTimeout={!is850Screen ? idleTimeout : undefined}
      maxDesktopHeight={140}
      maxMobileHeight={140}
      padding={-40}
      active={active}
      customHeader={rootHeader}
      classes={{
        header: styles.dropdownHeader,
        container: cn(styles.dropdownContainer, styles[`${displayMode}DropdownContainer`]),
      }}
      onToggle={handleToggleDropdown}
      contentHeader={
        <div className={cn(styles.headerContainer, styles[`${displayMode}HeaderContainer`])}>{dropdownHeader}</div>
      }
      width="auto"
      placement="bottom-end"
      contentTestProps={testMarker?.list.nodeProps}
    >
      <div className={cn(styles.locales, styles[`${displayMode}Locales`])}>{content}</div>
    </Dropdown>
  );
};

export const LocaleButton = memo(BaseLocaleButton);
