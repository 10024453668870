import type { Ref, RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type ScrollBar from 'react-perfect-scrollbar';
import { useDisableBodyScroll } from '@mwl/core-lib';

import { useTypedDispatch, useTypedSelector } from '@/hooks';
import { toggleSidebar } from '@/store/features/ui/actions';
import { selectSidebarIsOpen } from '@/store/features/ui/selectors';

let timeout: NodeJS.Timeout | null = null;

interface UseSidebarResult {
  isVisible: boolean;
  isRender: boolean;
  parentRef: RefObject<HTMLDivElement>;
  scrollableRef: Ref<ScrollBar & HTMLDivElement>;
  onClose: () => void;
}

const visibleTimeOut = 50;
const renderTimeOut = 300;

export const useSidebar = (): UseSidebarResult => {
  const dispatch = useTypedDispatch();

  const [isRender, setIsRender] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const scrollableRef = useRef<ScrollBar & HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const isShow = useTypedSelector(selectSidebarIsOpen);

  const onClose = useCallback(() => dispatch(toggleSidebar(false)), [dispatch]);

  useDisableBodyScroll({
    isDisable: isVisible,
    popupRef: parentRef,
    applyMargin: false,
  });

  useEffect(() => {
    if (!scrollableRef.current) {
      return;
    }

    if (isShow) {
      setIsRender(true);

      timeout = setTimeout(() => {
        setIsVisible(true);
      }, visibleTimeOut);
    } else {
      setIsVisible(false);

      timeout = setTimeout(() => {
        setIsRender(false);
      }, renderTimeOut);
    }
  }, [isShow]);

  useEffect(() => {
    return () => {
      if (parentRef.current) {
        parentRef.current = null;
      }

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return {
    isRender,
    isVisible,
    parentRef,
    scrollableRef,
    onClose,
  };
};
