import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { isBefore } from 'date-fns/isBefore';
import { useTranslation } from 'next-i18next';

import type { CashbackProps } from './Cashback.types';

import styles from './Cashback.module.scss';

const Cashback: FC<CashbackProps> = ({ cashbackBonus, cashbackPercent }) => {
  const { t } = useTranslation('loyalty');

  const [_canTake, setCanTake] = useState(false);
  // все закомментированные куски кода могут пригодиться после уточнения ТЗ
  // в макетах пока эти элементы присутствуют
  // https://jira.dats.tech/browse/MWL-7064
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const today = new Date();

    setCanTake(
      cashbackBonus?.status !== 'ISSUED' && cashbackBonus?.activateUntil
        ? isBefore(today, new Date(cashbackBonus?.activateUntil))
        : false,
    );
  }, [cashbackBonus?.activateUntil, cashbackBonus?.status]);

  // const handleClickRecieve = useCallback(async () => {
  //   if (cashbackBonus) {
  //     setIsLoading(true);
  //     const { status } = await activateBonus(cashbackBonus.uuid);

  //     if (status === 200) {
  //       setCanTake(false);
  //       onRecieve();
  //     }

  //     setIsLoading(false);
  //   }
  // }, [cashbackBonus, onRecieve]);

  // const renderer = ({ days, hours, minutes }) => {
  //   return (
  //     <span className={styles.date}>
  //       {days}d. {hours}:{minutes}
  //     </span>
  //   );
  // };

  return (
    <div className={styles.root}>
      <span className={styles.amount}>{cashbackPercent && ` ${t('cashback', 'Cashback:')} ${cashbackPercent}%`}</span>
      {
        // canTake ? (
        //   <Button
        //     className={styles.recieveBtn}
        //     onClick={handleClickRecieve}
        //     color="red"
        //     as="button"
        //     size="none"
        //     fontSize="medium"
        //     fontWeight="bold"
        //     disabled={cashbackPercent === 0 || isLoading}
        //   >
        //     {t('recieve', 'Recieve')}
        //   </Button>
        // ) :
        // <div className={styles.untilNext}>
        //   <span className={styles.label}>{t('until_cashback', 'Until cashback:')}</span>
        //   <Countdown renderer={renderer} date={nextMonday(new Date())} />
        // </div>
      }
    </div>
  );
};

export { Cashback };
