import { nanoid } from '@reduxjs/toolkit';

import { routes } from '@/constants';

import type { NavigationLink } from './Navigation.types';

export const links: Array<NavigationLink> = [
  {
    id: nanoid(),
    key: 'footer.navigation.about',
    href: routes.promo.about,
    text: 'About Us',
    analytics: {
      click: {
        eventName: 'footer_aboutUs_link_click',
      },
    },
  },
  {
    id: nanoid(),
    key: 'footer.navigation.affiliates',
    href: 'https://banzai.partners/',
    text: 'Affiliates',
    analytics: {
      click: {
        eventName: 'footer_affiliates_link_click',
      },
    },
  },
  {
    id: nanoid(),
    key: 'footer.navigation.contacts',
    href: routes.contacts,
    text: 'Contacts',
    analytics: {
      click: {
        eventName: 'footer_contactUs_link_click',
      },
    },
  },
  // Временно скрыт. Задача https://jira.dats.tech/browse/MWL-7143
  // {
  //   id: nanoid(),
  //   key: 'footer.navigation.payment',
  //   href: routes.payment.deposit,
  //   text: 'Payment',
  // },
  {
    id: nanoid(),
    key: 'footer.navigation.faq',
    href: routes.faq,
    text: 'FAQ',
    analytics: {
      click: {
        eventName: 'footer_faq_link_click',
      },
    },
  },
  {
    id: nanoid(),
    key: 'footer.navigation.terms_and_conditions',
    href: routes.rules,
    text: 'Terms and Conditions',
    analytics: {
      click: {
        eventName: 'footer_rules_link_click',
      },
    },
  },
  {
    id: nanoid(),
    key: 'footer.navigation.promotion_rules',
    href: routes.promotionRules,
    text: 'Promotion Rules',
    analytics: {
      click: {
        eventName: 'footer_promotionRules_link_click',
      },
    },
  },
  {
    id: nanoid(),
    key: 'footer.navigation.security_privacy_policy',
    href: routes.privacyPolicy,
    text: 'Security & Privacy Policy',
    analytics: {
      click: {
        eventName: 'footer_privacyPolicy_link_click',
      },
    },
  },
];
