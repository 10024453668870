import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { getSymbolFromCurrency, numberFormatter } from '@mwl/core-lib';

import CloseIcon from '@public/assets/common/icons/close.svg';

import { Countdown } from '@/components/Countdown/Countdown';
import { ProgressBar } from '@/components/ProgressBar/ProgressBar';

import type { SidebarBonusItemProps } from './SidebarBonusItem.types';

import styles from './SidebarBonusItem.module.scss';

const SidebarBonusItem = ({
  balance,
  currency,
  rollingBalance,
  requiredRollingBalance,
  expirationDate,
  className,
  onComplete,
}: SidebarBonusItemProps) => {
  const { t } = useTranslation();
  const currencySign = getSymbolFromCurrency(currency);

  const percent = useMemo(() => {
    if (!requiredRollingBalance || !rollingBalance) {
      return 0;
    }

    return (100 * rollingBalance) / requiredRollingBalance;
  }, [rollingBalance, requiredRollingBalance]);

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.progress}>
        <ProgressBar
          radiusValue={37}
          size={90}
          variant="circle"
          percent={percent || 1}
          showValue
          value={
            <span className={styles.progressValue}>
              <strong>%</strong>
              {percent.toFixed(1)}
            </span>
          }
        />
      </div>
      <div className={styles.text}>
        <div className={styles.headerBalance}>
          <span className={styles.header}>{t('loyalty:casino_bonus.title', 'Casino bonus')}</span>
          <span>{`${currencySign} ${numberFormatter(Number(balance))}`}</span>
        </div>
        <div className={styles.expirationDate}>
          <span className={styles.burnOut}>{t('sidebar.timer.title', 'Burn out:')}</span>
          <Countdown date={expirationDate} daysInHours onComplete={onComplete} />
        </div>
      </div>

      <CloseIcon onClick={onComplete} className={styles.close} />
    </div>
  );
};

export { SidebarBonusItem };
