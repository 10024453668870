import type { FC } from 'react';
import { useSwitchValue } from '@mwl/core-lib';

import MoreIcon from '@public/assets/common/icons/more.svg';

import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import { Tooltip } from '@/components/Tooltip/Tooltip';

import { AdditionalNavItem } from '../AdditionalNavItem/AdditionalNavItem';

import type { AdditionalNavProps } from './AdditionalNav.types';

import styles from './AdditionalNav.module.scss';

const AdditionalNav: FC<AdditionalNavProps> = ({
  classes,
  links,
  offset = [0, 20],
  placement = 'bottom',
  testMarker,
}) => {
  const { value: isShown, on: onShow, off: onHide } = useSwitchValue(false);

  if (links.length === 0) {
    return null;
  }

  const list = (
    <div {...testMarker?.tooltip.nodeProps}>
      {links.map(({ href, text, key }) => (
        <AdditionalNavItem key={key} classes={classes} href={href} translationKey={key} translationText={text} />
      ))}
    </div>
  );

  return (
    <Tooltip
      visible={isShown}
      maxWidth={213}
      onHide={onHide}
      onClickOutside={onHide}
      variant="arrow"
      placement={placement}
      content={list}
      offset={offset}
      classes={{
        content: styles.content,
        wrapper: cn(styles.tooltip, classes?.tooltipWrapper),
      }}
    >
      <ButtonIcon
        cut
        skew="right"
        variant={!isShown ? 'text' : 'fulfilled'}
        color={!isShown ? 'white' : 'red'}
        as="button"
        size="sm"
        aria-label="more"
        className={cn(styles.button, { [styles.opened]: isShown })}
        onClick={onShow}
        {...testMarker?.dots.nodeProps}
      >
        <MoreIcon width={16} height={16} />
      </ButtonIcon>
    </Tooltip>
  );
};

export { AdditionalNav };
