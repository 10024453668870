import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import CloseIcon from '@public/assets/common/icons/close.svg';

import { Button } from '@/components/Button/Button';
import { Countdown } from '@/components/Countdown/Countdown';
import { Image } from '@/components/Image/Image';
import { routes } from '@/constants';
import { useTypedDispatch } from '@/hooks';
import { toggleSidebar } from '@/store/features/ui/actions';

import type { SidebarFreespinItemProps } from './SidebarFreespinItem.types';

import styles from './SidebarFreespinItem.module.scss';

const SidebarFreespinItem = ({
  id,
  title,
  count,
  playedCount,
  image,
  expirationDate,
  className,
  onComplete,
}: SidebarFreespinItemProps) => {
  const { t } = useTranslation('common');

  const dispatch = useTypedDispatch();

  const href = useMemo(() => ({ pathname: routes.casino.game, query: { gameId: id, mode: 'real' } }), [id]);

  const handleClick = () => dispatch(toggleSidebar(false));

  return (
    <div className={cn(styles.root, className)}>
      <Button variant="text" as="link" href={href} onClick={handleClick} className={styles.link}>
        <Image src={image} width={90} height={66} className={styles.image} alt={title} />
        <div className={styles.text}>
          <div className={styles.headerTitle}>
            <span className={styles.header}>{`${count - (playedCount || 0)} ${t(
              'sidebar.freespin.count',
              'freespins',
            )}`}</span>
            <span className={styles.gameTitle}>{title}</span>
          </div>
          <div className={styles.expirationDate}>
            <span className={styles.burnOut}>{t('sidebar.timer.title', 'Burn out:')}</span>
            <Countdown date={expirationDate} daysInHours onComplete={onComplete} />
          </div>
        </div>
      </Button>
      <CloseIcon onClick={onComplete} className={styles.close} />
    </div>
  );
};

export { SidebarFreespinItem };
