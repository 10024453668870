import type { FC } from 'react';
import { memo } from 'react';
import { selectUserIsAuthenticated, useAviator, useLogos } from '@mwl/core-lib';

import { Image } from '@/components/Image/Image';
import { PerfectScrollbar } from '@/components/PerfectScrollbar/PerfectScrollbar';
import { routes } from '@/constants';
// import SocialList from '@/components/SocialList/SocialList';
import { useTypedSelector } from '@/hooks';
import { userMenuObject } from '@/utils';

import { MenuButton } from '../Header/components/MenuButton/MenuButton';

import { Menu } from './components/Menu/Menu';
import { RegisterBanner } from './components/RegisterBanner/RegisterBanner';
import { SidebarBonus } from './components/SidebarBonus/SidebarBonus';
import { UserInfo } from './components/UserInfo/UserInfo';
import { useSidebar } from './hooks/useSidebar';
import { useUpdateUserSettingsError } from './hooks/useUpdateUserSettingsError';
import { getSidebarMenuElements } from './Sidebar.utils';

import styles from './Sidebar.module.scss';

const BaseSidebar: FC = () => {
  const { main } = useLogos();
  const isAuth = useTypedSelector(selectUserIsAuthenticated);

  useUpdateUserSettingsError();

  const aviatorHref = useAviator({ routes });

  const sidebarMenuElements = getSidebarMenuElements(aviatorHref);

  const { isRender, isVisible, scrollableRef, parentRef, onClose } = useSidebar();

  return (
    <div
      {...userMenuObject.nodeProps}
      className={cn(styles.root, { [styles.render]: isRender, [styles.visible]: isVisible })}
    >
      <div className={styles.overlay} onClick={onClose} role="presentation" />
      <div className={styles.container} ref={parentRef}>
        <div className={styles.content}>
          <div className={styles.topContent}>
            {!isAuth ? (
              <div className={styles.logoImage}>
                <Image src={main} priority fill alt="Banzai" />
              </div>
            ) : (
              <UserInfo />
            )}

            <MenuButton shouldOpen={false} className={styles.closeButton} />
          </div>
          <div className={styles.scrollbar}>
            <PerfectScrollbar ref={scrollableRef} className={styles.scrollbarContainer}>
              {!isAuth && <RegisterBanner />}
              {isAuth && <SidebarBonus />}
              <Menu elements={sidebarMenuElements} />
              {/* <div className={styles.socialListContainer}><SocialList className={styles.list} /></div> */}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Sidebar = memo(BaseSidebar);
