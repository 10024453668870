import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useLogos } from '@mwl/core-lib';
import { NoSSR } from '@mwl/ui';

import AdultIcon from '@public/assets/common/icons/18plus.svg';

import { Button } from '@/components/Button/Button';
import { Download } from '@/components/Download/Download';
import { Image } from '@/components/Image/Image';
import { routes } from '@/constants';
import { useMediaWidthDown } from '@/hooks';
import { appPageObject } from '@/utils';

import { GCBValidator } from './components/GCBValidator/GCBValidator';
import { Navigation } from './components/Navigation/Navigation';
import { PaymentsList } from './components/PaymentsList/PaymentsList';
import { PoweredBy } from './components/PoweredBy/PoweredBy';
import { ProviderList } from './components/ProviderList/ProviderList';
import content from './content.json';
import { analytics } from './Footer.analytics';
import { useWithValidator } from './Footer.hooks';
import type { FooterProps } from './Footer.types';

import styles from './Footer.module.scss';

const Footer: FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation();

  const { main, app } = useLogos();
  const withValidator = useWithValidator();
  const isXsScreen = useMediaWidthDown('screenXs');

  return (
    <footer
      {...appPageObject.footer.nodeProps}
      className={cn(styles.root, className, { [styles.withoutValidator]: !withValidator })}
    >
      <ProviderList className={styles.providers} analytics={analytics.providers} />

      <Navigation className={styles.navigation} />

      <NoSSR>
        <Button variant="text" className={styles.logo} as="link" href={routes.home}>
          <Image
            src={withValidator ? app : main}
            width={withValidator ? 75 : 282}
            height={withValidator ? 75 : 48}
            priority
            alt="Validator"
          />
        </Button>
      </NoSSR>

      {withValidator && (
        <div className={styles.content}>
          {t('footer.information', content.information)} {t('footer.description', content.description)}
        </div>
      )}

      <div className={styles.downloadWrapper}>
        <Download
          variant="footer"
          className={styles.download}
          alwaysShowText={!withValidator}
          testMarker={appPageObject.footer.downloads('android_download')}
          analytics={analytics.download}
        />
        <AdultIcon className={styles.adultIcon} />
        <GCBValidator />
        {isXsScreen && withValidator && <PoweredBy className={styles.poweredBy} />}
      </div>

      <PaymentsList className={styles.payments} />

      {(!isXsScreen || !withValidator) && <PoweredBy className={styles.poweredBy} />}
    </footer>
  );
};

export * from './Footer.types';
export { Footer };
