import type { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData } from '@mwl/core-lib';

import AndroidIcon from '@public/assets/common/icons/android.svg';

// import AppleIcon from '@public/assets/common/icons/apple.svg';
import { routes } from '@/constants';
import { useMediaWidthDown, useTypedDispatch } from '@/hooks';
import { toggleSidebar } from '@/store/features/ui/actions';

import { ButtonIcon } from '../ButtonIcon/ButtonIcon';

import type { DownloadProps } from './Download.types';

import styles from './Download.module.scss';

const Download: FC<DownloadProps> = ({ variant, className, alwaysShowText = false, testMarker, analytics }) => {
  const { t } = useTranslation();

  const isSmallScreen = useMediaWidthDown('screenMd');
  const dispatch = useTypedDispatch();
  const router = useRouter();

  const androidHref = { pathname: routes.download, query: { platform: 'android' } };

  const handleClickButton = () => {
    router.push(androidHref);
    dispatch(toggleSidebar(false));
  };

  return (
    <div {...testMarker?.button.nodeProps} className={cn(styles.root, className, styles[variant])}>
      {(!isSmallScreen || alwaysShowText) && (
        <span className={styles.text}>{t('header.navigation.download', 'download')}:</span>
      )}

      {/* TODO Временно скрываем в рамках MWL-6896 
      
      <ButtonIcon as="link" href={{ pathname: routes.download, query: { platform: 'ios' } }} variant="text">
        <AppleIcon />
      </ButtonIcon> */}

      <ButtonIcon
        {...testMarker?.icon.nodeProps}
        as="button"
        onClick={handleClickButton}
        variant="text"
        aria-label="android"
        analytics={extendAnalyticsData(analytics?.android, { href: androidHref })}
      >
        <AndroidIcon />
      </ButtonIcon>
    </div>
  );
};

export { Download };
