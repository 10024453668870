import { useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { selectUserUpdateSettingsError, showModal } from '@mwl/core-lib';
import { nanoid } from '@reduxjs/toolkit';

import { useTypedDispatch, useTypedSelector } from '@/hooks';

const updateSettingsErrorModalId = nanoid();

export const useUpdateUserSettingsError = () => {
  const updateSettingsError = useTypedSelector(selectUserUpdateSettingsError);

  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const isShowRef = useRef<boolean>(false);

  useEffect(() => {
    if (!updateSettingsError && isShowRef.current) {
      isShowRef.current = false;
    }

    if (updateSettingsError && !isShowRef.current) {
      isShowRef.current = true;

      dispatch(
        showModal({
          id: updateSettingsErrorModalId,
          message: Array.isArray(updateSettingsError) ? t(updateSettingsError) : updateSettingsError,
          status: 'error',
        }),
      );
    }
  }, [updateSettingsError, dispatch, t]);
};
