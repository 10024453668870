import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import type { NavMenuItem } from '@/constants';

interface UseAdditionalNavProps {
  links: Array<NavMenuItem>;
  containerRef: RefObject<HTMLElement | null>;
  shadowRef: RefObject<HTMLDivElement | null>;
  numberRemovedLinks?: number;
}

const offsettingWidth = 160;

/**
 * Возвращает два массива с навигационными элементами и дополнительными элементами.
 * Массив с навигационными элементами содержит только элементы, которые могут быть видны на экране.
 * Массив дополнительных элементов содержит все элементы, которые могут быть видны на экране.
 */
export const useAdditionalNav = ({ links, containerRef, shadowRef, numberRemovedLinks = 0 }: UseAdditionalNavProps) => {
  const [visibleLinks, setVisibleLinks] = useState(links);
  const [otherLinks, setOtherLinks] = useState<typeof links>([]);

  const calcVisibleLinks = useCallback(() => {
    if (!containerRef.current || !shadowRef.current) {
      return;
    }

    const { clientWidth } = containerRef.current;
    const { children } = shadowRef.current;
    let visibleLinksWidth = 0;
    let countVisibleLinks = links.length;

    for (let i = 0; i < children.length; i += 1) {
      const linkElWidth = (children[i] as HTMLElement).offsetWidth;

      if (linkElWidth + visibleLinksWidth < clientWidth - offsettingWidth) {
        visibleLinksWidth += linkElWidth;
      } else {
        countVisibleLinks = i - numberRemovedLinks;
        break;
      }
    }

    setVisibleLinks(links.slice(0, countVisibleLinks));
    setOtherLinks(links.slice(countVisibleLinks));
  }, [containerRef, links, numberRemovedLinks, shadowRef]);

  useEffect(() => {
    const onResize = () => {
      calcVisibleLinks();
    };

    const resizeObserver = new ResizeObserver(onResize);

    const linksContainerEl = containerRef.current;

    if (linksContainerEl) {
      resizeObserver.observe(linksContainerEl);
    }

    setTimeout(onResize, 16); // call on approximately on next frame

    return () => {
      if (linksContainerEl) {
        resizeObserver.unobserve(linksContainerEl);
      }
    };
  }, [links, calcVisibleLinks, containerRef]);

  return {
    visibleLinks,
    otherLinks,
  };
};
