import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { selectBonusPackets, useQueryPopupOpen } from '@mwl/core-lib';

import imgLeft from '@public/assets/sidebar/images/regBonusLeft.png';
import imgRight from '@public/assets/sidebar/images/regBonusRight.png';

import { Button } from '@/components/Button/Button';
import { Image } from '@/components/Image/Image';
import { useTypedSelector } from '@/hooks';

import styles from './RegisterBanner.module.scss';

const BaseRegisterBanner: FC = () => {
  const { t } = useTranslation('notification');
  const { createLinkProps } = useQueryPopupOpen();
  const registerLinkProps = useMemo(() => createLinkProps({ queryName: 'register' }), [createLinkProps]);
  const bonuses = useTypedSelector(selectBonusPackets);

  const bonus = bonuses.find(({ registrationBonusType }) => registrationBonusType === 'CASINO');

  return (
    <div className={styles.root}>
      <div className={styles.backImages}>
        <div className={styles.imgContainer}>
          <Image
            src={imgLeft}
            priority
            fill
            style={{
              objectFit: 'contain',
              objectPosition: 'left',
            }}
            alt={bonus?.bonusTitle || 'Bonus'}
          />
        </div>
        <div className={styles.imgContainer}>
          <Image
            src={imgRight}
            priority
            fill
            style={{
              objectFit: 'contain',
              objectPosition: 'right',
            }}
            alt={bonus?.bonusTitle || 'Bonus'}
          />
        </div>
      </div>
      <div className={styles.container}>
        <span className={styles.bonus}>{bonus?.bonusTitle}</span>
        <span className={styles.deposit}>
          {t('sidebar_register_bonus_for_the_first_deposit', 'For the first deposit')}
        </span>
        <Button
          as="link"
          variant="fulfilled"
          size="md"
          fontSize="xl"
          fontWeight="bold"
          fullWidth
          rounded
          {...registerLinkProps}
        >
          {t('sidebar_register_bonus_take_now', 'take now')}
        </Button>
      </div>
    </div>
  );
};

export const RegisterBanner = memo(BaseRegisterBanner);
