import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';
import { handleEventWithAnalytics } from '@mwl/core-lib';
import { AccordionBase, AccordionContent, AccordionControl } from '@mwl/ui';

import DefaultArrowIcon from '@public/assets/common/icons/arrow.svg';

import type { AccordionProps } from './Accordion.types';

import styles from './Accordion.module.scss';

const BaseAccordion: FC<AccordionProps> = ({
  ArrowIcon,
  className,
  classes,
  title,
  titleUppercase = true,
  opened = false,
  analytics,
  children,
  onOpen,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(opened);

  const handleClick = useCallback(() => {
    setIsOpen((open) => !open);
    onOpen?.(isOpen);
  }, [isOpen, onOpen]);

  const analyticsEvent = isOpen ? analytics?.close : analytics?.open;

  const analyticsClassName: string | undefined = analyticsEvent?.data?.cls;

  const Icon = ArrowIcon ?? DefaultArrowIcon;

  return (
    <AccordionBase
      expanded={isOpen}
      className={cn(styles.root, className, { [styles.active]: isOpen })}
      chevron={<Icon className={styles.icon} />}
      {...rest}
    >
      <AccordionControl
        className={cn(analyticsClassName, styles.button, classes?.button)}
        classes={{ chevron: cn(styles.iconContainer, classes?.iconContainer) }}
        onClick={handleEventWithAnalytics(handleClick, analyticsEvent?.eventName, analyticsEvent?.data)}
      >
        <span className={cn(styles.text, { [styles.uppercase]: titleUppercase }, classes?.text)}>{title}</span>
      </AccordionControl>
      <AccordionContent className={cn(styles.content, classes?.content)}>{children}</AccordionContent>
    </AccordionBase>
  );
};

const Accordion = memo(BaseAccordion);

export { Accordion };
